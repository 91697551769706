import { PropsWithChildren } from 'react'
import ErrorBoundary from './ErrorBoundary'

export default function ChunkLoadErrorHandler({ children }: PropsWithChildren<unknown>) {
    return (
        <ErrorBoundary
            fallback={(error: Error) => {
                const chunkFailedMessage = /Loading chunk/g
                if (chunkFailedMessage.test(error.message as string)) {
                    window.location.reload()
                }
            }}>
            {children}
        </ErrorBoundary>
    )
}
