import { createRoot } from 'react-dom/client'
import './index.css'
import * as hasOwn from 'object.hasown'
import { Suspense, lazy } from 'react'
import ChunkLoadErrorHandler from './core/components/ChunkLoadErrorHandler'

// Object.hasOwn polyfill for Safari
if (!Object.hasOwn) {
    hasOwn.shim()
}

const AppRoot = lazy(() => import('./AppRoot'))

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <Suspense fallback={<AppFallback />}>
        <ChunkLoadErrorHandler>
            <AppRoot />
        </ChunkLoadErrorHandler>
    </Suspense>
)

export function AppFallback() {
    return <div className='w-full h-full flex flex-col justify-center items-center bg-gray-100' />
}
