import { Component } from 'react'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null
        }
    }

    static getDerivedStateFromError(error) {
        return { error }
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error(error)
    }

    render() {
        const { fallback, children } = this.props
        const { error } = this.state

        if (Boolean(error)) {
            if (typeof fallback === 'function') {
                return fallback(error)
            }
            return fallback
        }

        return children
    }
}
